//Device specific breakpoints

$desktop: 1440px;
$tablet: 1200px;
$mobile: 600px;


@mixin desktop{

  @media only screen 
  and (min-width: #{$tablet}) 
  and (max-width: #{$desktop}) {
    @content;
  }

}

@mixin tablet{

  @media only screen 
  and (min-width: #{$mobile}) 
  and (max-width: #{$tablet}) {
    @content;
  }

}

@mixin mobile{

  @media only screen
  and (max-width: #{$mobile}) {
    @content;
  }

}