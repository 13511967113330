.my-interview { 
    padding: 50px;
    background-color: white;
    margin: 10px;
    box-shadow: -1px 2px 7px -2px rgba(125,123,125,1);

    h3 {
        margin: 0%;
    }

    p{
        margin: 0px 0px 20px 0px;
    }

    a{
        color: black;
        &:hover{
            color: gray;
            text-decoration: underline;
        }
    }

    &__text{
        padding: 40px;
    }

    &__question{

    }

    &__answer{
        
    }

    hr {
        border: 0.5px solid #ededed;
    }
}