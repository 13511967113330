.projects{
    display: flex;
    justify-content: center;
    flex-direction: column;
   

    &__search-bar {
        display: flex;
        flex:1;
        align-items: center;
        justify-content: center;
        margin-top: 20px;

        &__input{
            height: 35px;
            width: 80%;
            text-decoration: none;
            font-family: $primary-font;
            text-align: center;
            font-size: 1em;
            box-shadow: -1px 2px 7px -2px rgba(125,123,125,1);
            border: none;

            &:hover {
                box-shadow: -1px 2px 7px -2px rgb(87, 87, 87); 
            }
            &:focus{
                outline: none;
            }
        }
    }

    &__header{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin: 20px 4% 1% 4%;
    }
    
}

.header{
    &__resume {
        margin-left: 1%;
    }
}


.p-cards__p-card{
    width: 100%;

    @include mobile{
        padding-top: 5%;
    }

}

.p-card{
    &__date{
        padding: 10px 0 10px 0;
        position: absolute;
        display: flex;
        flex: 1;
        
        &-text{
            font-family: $primary-font;
            background-color: #F7F7F7;
            padding: 3px 11px 3px 17px;
            color: #707072;
            box-shadow: -1px 2px 7px -2px rgba(125,123,125,0.5);
        }

        @include desktop{
            left: 83%;
            margin: 1% 2% 1% 0%;
        }
    }
    &__body{
        z-index: -1;
        background-color: white;
        box-shadow: -1px 2px 7px -2px rgba(125,123,125,1);
        width: 96%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 50px 0 10px 0px;
        margin: 2% 2%;
        padding: 50px 0 10px 0px;

        &:hover {
            box-shadow: -1px 2px 7px -2px rgb(87, 87, 87);  
        }
        
        @include desktop{
            padding: 20px 0 10px 0px;
          }
      
        @include tablet{
        }
    
        @include mobile{
            margin: 0 2%;
        }
    }

    &__title{
        padding: 0 5%;

        &-text{
            font-family: $primary-font;
            font-weight: bold;

            &--project-name{
                font-weight: 100;
            }
        }

        @include desktop{
            padding: 0 2%;
          }
      
        // @include tablet{
        //     padding: 0 5%;
        // }
    
        // @include mobile{
        //     padding: 0 5%;
        // }

    }

    &__description{

        // text-align: justify;
        font-weight: 100;
        padding: 0% 5%;

        // @include desktop{
        //     padding: 0% 2%;
        //   }
      
        // @include tablet{
        //     padding: 2% 5%;
        // }
    
        // @include mobile{
        //     padding: 2% 5%;
        // }

        &-text{
            font-family: $primary-font;
            color: #7C7C7C;
        }

    }

    &__buttons{
        margin-top:1%;
        display: flex;
        flex:1;
        width: 100%;
        justify-content: flex-end;
        flex-direction: row;
    }

    &__button{
        margin: 0 30px 0 5px;
        font-family: $primary-font;
        border: 1px solid $primary-color;
        padding: 8px;
        color: white;
        font-weight: 100;
        background-color: $primary-color;
        cursor: pointer;
    }

    &__tech-stack {
        margin: 1% 2% 1% 3%;

        @include mobile{
            margin: 2% 4%;
        }
    }
    
}

.tech-stack{

    &__title{
        font-weight: bold;
    }

    &__body{
        color: #7C7C7C;
        padding: 1% 0%;
        text-align: justify;
    }
}