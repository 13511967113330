.blog{
    display: flex;
    justify-content: center;
    flex-direction: column;
   

    &__search-bar {
        display: flex;
        flex:1;
        align-items: center;
        justify-content: center;
        margin-top: 20px;

        &__input{
            height: 35px;
            width: 80%;
            text-decoration: none;
            font-family: $primary-font;
            text-align: center;
            font-size: 1em;
            box-shadow: -1px 2px 7px -2px rgba(125,123,125,1);
            border: none;

            &:hover {
                box-shadow: -1px 2px 7px -2px rgb(87, 87, 87); 
            }
            &:focus{
                outline: none;
            }
        }
    } 
}

.lz-cards__lz-card{
    width: 100%;

    @include mobile{
        padding-top: 5%;
      }

}

.lz-card{
    
    &__date{
        padding: 10px 0 10px 0;
        position: absolute;
        display: flex;
        
        &-text{
            background: rgb(237, 237, 237);
            width: 100px;
            font-family: $primary-font;
           // background-color: #F7F7F7;
            padding: 20px 11px 3px 17px;
            color: #707072;
            box-shadow: -1px 2px 7px -2px rgba(125,123,125,1);
        }
    }
    &__body{
        z-index: -1;
        background-color: white;
        box-shadow: -1px 2px 7px -2px rgba(125,123,125,1);
        width: 96%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 50px 0 10px 0px;
        margin: 2% 2%;
        padding: 50px 0 10px 0px;

        &:hover {
            box-shadow: -1px 2px 7px -2px rgb(87, 87, 87);  
        }
        @include desktop{
          }
      
          @include tablet{
          }
      
          @include mobile{
            margin: 0 2%;
          }
    }

    &__title{
        background: rgb(206, 206, 206);
        width: 50%;
        margin: 10px;

        &-text{
            font-family: $primary-font;
            font-weight: bold;
        }

        @include desktop{
            padding: 0 1%;
          }
      
        @include tablet{
            padding: 0 1%;
        }
    
        @include mobile{
            flex:1;
            padding: 0 3%;
        }

    }

    &__description{

        background: rgb(206, 206, 206);
        width: 80%;
        margin: 0px 10px;
        height: 30px;

        @include desktop{
            padding: 0 1%;
          }
      
        @include tablet{
            padding: 0 1%;
        }
    
        @include mobile{
            padding: 1% 3%;
        }

        &-text{
            font-family: $primary-font;
            color: #7C7C7C;
        }

    }

    &__buttons{
        margin-top:1%;
        display: flex;
        flex:1;
        width: 100%;
        justify-content: flex-end;
        flex-direction: row;
    }

    &__button{
        margin: 0 30px 0 5px;
        font-family: $primary-font;
        padding: 8px;
        color: white;
        font-weight: 100;
        background: rgb(206, 206, 206);
        cursor: pointer;
        padding: 15px 30px;
    }
}
