.footer {
    background-color: $primary-color;
    padding: 10px 0 10px 0;
    display: flex;

    &__left{ 
      align-items: center;
      justify-content: center;
      display: flex;
      color: white;
      margin: 10px 0px 10px 10px;
      opacity: 0.5;

      @include mobile{
        margin: 10px 0px;
        font-size: 0.5em;
        flex: 1;
      }

      @include tablet{
        margin: 10px 0px;
        font-size: 0.4em;
        flex: 1;
      }

    }

    &__right{
      flex: 1;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    @include mobile{
      padding: 20px 0px;
      flex-direction: column;
    }

    &__logo {
      margin-left: 10px;
      display: flex;
      flex-direction: row;

      @include mobile{
        margin: 10px 0px;
      }

    }

    &__links{
      display: flex;
      flex: 1;
      margin-right: 20px;
      align-items: center;
      justify-content: flex-end;

      @include mobile{
        justify-content: center;
        margin-right: 0px;
      }


    }
}

.links__link{
  font-family: $logo-font;
  color: white;
  justify-content: flex-end;
  cursor: pointer;

  &--active{
    color:lighten(grey,5%);
    cursor: auto;
  }

  &--seperator{
    font-weight: 100;
    margin: 0px 5px;
    font-family: $logo-font;
    color: white;
    justify-content: flex-end;
    opacity: 0.1;
  }
}

.logo__name{
  font-size: 2vw;
  color: white;
  font-family: $logo-font;

  @include desktop{
    font-size: 2vw;
  }

  @include tablet{
    font-size: 4vw;
  }

  @include mobile{
    font-size: 6vw;
  }


  &--last{
    color: transparent;
    font-size: 2vw;
    font-family: $logo-font;
    -webkit-text-stroke-width: 0.8px;
    -webkit-text-stroke-color: rgb(255, 255, 255);

    @include desktop{
      font-size: 2vw;
    }
  
    @include tablet{
      font-size: 4vw;
    }
  
    @include mobile{
      font-size: 6vw;
    }

  }

}
  
