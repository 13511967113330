.btn{
    cursor: pointer;
    align-items: center;
    color: black;
    height: 30px;
    padding: 4px 10px;
    display: flex;
    background-color: $primary-color;

    &__text{
        color: #ffffff;
        font-weight: bold;
        font-family: $logo-font;
    }
}

