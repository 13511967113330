.markdown {
  //background-color: white;
  padding: 1% 2%;

  pre {
    code {
      border: none;
      padding: 10px 15px;
    }
  }

  a {
    text-decoration: underline;
    color: $primary-color;
  }

  ::selection {
    background: $primary-color; /* WebKit/Blink Browsers */
    color: white;
  }

  h4 {
    margin: 4px;
  }

  &__card {
    margin: 1%;
    background-color: white;
    padding: 0.1% 2%;
    border: 1px solid #00000021;
    box-shadow: -1px 2px 7px -2px #7d7b7d38;

    @include tablet {
      padding: 1% 3%;
    }

    @include mobile {
      padding: 0.5% 5%;
    }
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
    color: black;
  }

  &__search-bar {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin: 2% 0%;

    input {
      height: 50px;
      // width: 83%;
      flex: 1;
      text-decoration: none;
      //border-radius: 25px;
      font-family: $primary-font;
      font-weight: bold;
      display: flex;
      //text-align: center;
      //padding-left: 25px;
      font-size: 1.1em;
      // box-shadow: -1px 2px 7px -2px #7d7b7d5c;
      border: none;

      &:hover {
        // box-shadow: -1px 2px 7px -2px rgb(87, 87, 87);
      }
      &:focus {
        outline: none;
      }
    }
  }
}

/* Tomorrow Night Theme */
/* http://jmblog.github.com/color-themes-for-google-code-highlightjs */
/* Original theme - https://github.com/chriskempson/tomorrow-theme */
/* http://jmblog.github.com/color-themes-for-google-code-highlightjs */
.tomorrow-comment,
pre .comment,
pre .title {
  color: #969896;
}

.tomorrow-red,
pre .variable,
pre .attribute,
pre .tag,
pre .regexp,
pre .ruby .constant,
pre .xml .tag .title,
pre .xml .pi,
pre .xml .doctype,
pre .html .doctype,
pre .css .id,
pre .css .class,
pre .css .pseudo {
  color: #cc6666;
}

.tomorrow-orange,
pre .number,
pre .preprocessor,
pre .built_in,
pre .literal,
pre .params,
pre .constant {
  color: #de935f;
}

.tomorrow-yellow,
pre .class,
pre .ruby .class .title,
pre .css .rules .attribute {
  color: #f0c674;
}

.tomorrow-green,
pre .string,
pre .value,
pre .inheritance,
pre .header,
pre .ruby .symbol,
pre .xml .cdata {
  color: #b5bd68;
}

.tomorrow-aqua,
pre .css .hexcolor {
  color: #8abeb7;
}

.tomorrow-blue,
pre .function,
pre .python .decorator,
pre .python .title,
pre .ruby .function .title,
pre .ruby .title .keyword,
pre .perl .sub,
pre .javascript .title,
pre .coffeescript .title {
  color: #81a2be;
}

.tomorrow-purple,
pre .keyword,
pre .javascript .function {
  color: #b294bb;
}

pre code {
  display: block;
  background: $primary-color;
  color: #c5c8c6;
  font-family: Menlo, Monaco, Consolas, monospace;
  line-height: 1.5;
  border: 1px solid #ccc;
  padding: 10px;
}
