.tilcard { 
    display: flex;
    padding: 0px 0px 125px 0px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: $primary-font;
    background-color: white;
    margin: 10px;
    box-shadow: -1px 2px 7px -2px rgba(125,123,125,1);
}